<template>
  <div>
    <techprocesses-add-waste></techprocesses-add-waste>
  </div>
</template>

<script>
import TechprocessesAddWaste from "@/components/ecologist/techprocesses/techprocessesAddWaste";
export default {
  name: "techprocessesAddWasteView",
  components: { TechprocessesAddWaste },
};
</script>

<style scoped></style>
