<template>
  <div>
    <h2>Добавить отход</h2>
    <div class="search">
      <h4 style="margin-top: 0">Поиск отход</h4>
      <el-row :gutter="10">
        <el-col :span="10">
          <el-select
            style="width: 100%"
            clearable
            v-model="orgCode"
            filterable
            placeholder="Select"
          >
            <el-option
              v-for="item in organizationsCodes"
              :key="item.id"
              :label="item.text"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="10">
          <el-select
            style="width: 100%"
            clearable
            v-model="orgName"
            filterable
            placeholder="Select"
          >
            <el-option
              v-for="item in organizationsNames"
              :key="item.id"
              :label="item.text"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button @click="search" type="primary">Найти</el-button>
        </el-col>
      </el-row>

      <br />
      <el-row :gutter="10">
        <el-col :span="24">
          <el-button @click="addWaste" type="primary">
            Добавить отход</el-button
          >
        </el-col>
      </el-row>
    </div>
    <tech-processes-add-waste-form
      v-if="foundedWaste != null"
    ></tech-processes-add-waste-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TechProcessesAddWasteForm from "@/components/ecologist/techprocesses/techProcessesAddWasteForm";

export default {
  props: ["action"],
  name: "techprocessesAddWaste",
  components: { TechProcessesAddWasteForm },
  data() {
    return {
      orgCode: null,
      orgName: null,
    };
  },

  computed: {
    ...mapGetters({
      addWastePage: "GETADDWASTEPAGE",
      organizationsCodes: "GETORGANIZATIONWASTESLIST",
      organizationsNames: "GETORGANIZATIONWASTESLISTBYNAME",
      foundedWaste: "GETFOUNDWASTETECHPROC",
    }),
  },

  methods: {
    search() {
      this.$store.dispatch("searchWasteTechProc", {
        techProcId: this.$route.params.id,
        code: this.orgCode,
        name: this.orgName,
      });
    },

    addWaste() {},
  },

  mounted() {
    this.$store.dispatch("showAddWastePage", this.$route.params.id);
    this.$store.dispatch(
      "getOrganizationWastesListByCode",
      this.$route.params.id
    );
    this.$store.dispatch(
      "getOrganizationWastesListByName",
      this.$route.params.id
    );
  },
};
</script>

<style scoped></style>
